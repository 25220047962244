<template>
  <div class="search_filter_wrap2" style="margin-top: 10px;">
    <div class="search_item_wrap2">
      <div class="search_input2">
        <el-input :placeholder="$t('st')" v-model="searchInput"
                  @keypress.enter.native="setFilterFunc('su',searchInput)"/>
        <button class="search_btn" @click="setFilterFunc('su',searchInput)"><span>{{$t('search')}}</span>
          <img src="@/assets/image/main/search_btn.png"></button>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import Filter from "@/mixins/filter/filter"
import boardUtil from "@/components/board/boardUtil";

const {filterConfig} = boardUtil
export default {
    name: "BoardFilterDefaultLayout",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort', 'setBoardListData'],
    provide() {
        return {}
    },
    props: {
      filterData: {default: () => {}},
      sortData: {default: () => {}},
      filterSet: {default: {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}}}
    },
    data() {
        return {
          searchOption: '',
          sortOption: 'updated_at',
          searchInput: '',
          filterClass: new Filter(),
          showFilter: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
      this.filterClass.setterFilterConfigs(filterConfig);
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
      setFilterFunc(key, value) {
        this.setFilter(key, value);
        this.filterClass.filter = Object.assign({}, this.filterSet.filter);
      },
      setData(key, value) {
        this[key] = value;
      },
      runRangeFilter() {
        let filterSet = this.filterSet;
        filterSet.filter = this.filterClass.setterFilter('isa', this.searchOption);
        this.setBoardListData('filterSet', filterSet);
        this.setBoardListData('filterClass', this.filterClass);
        EventBus.$emit('boardListChangeSaveFilter')
        // this.showFilter = false;

      },
    },
    watch: {},
}
</script>

<style scoped>

</style>